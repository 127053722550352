import React from 'react';
import PageTransition from '@/components/page-transition/PageTransition';
import Head from 'next/head'
import Header from '@/components/header/header'
import ContentArea from '@/components/content-area/content-area';
import Text from '@/components/text/text';
import Button from '@/components/button/button';
import Hero from '@/components/hero/hero';

type Error404PageProps = {}
type Error404PageRef = React.ForwardedRef<HTMLDivElement>

export default function Error404(props: Error404PageProps, ref: Error404PageRef) {


  return (
    <PageTransition>
      <Head>
        <title>eighteen yards</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header></Header>
      <main>
        <Hero
            text='small'
            title='Error 404 - Page not found' />
        <ContentArea hero>
          <>
          <Text size='large' type={'h3'} column={0} >Oops, that serve was off the mark</Text>
            <Text type={'p'} column={0} >Let&rsquo;s get your second serve on target!</Text>
            <Button link="/" title="return home" style="primary" column={4} />
       
          </>
        </ContentArea>
      </main>
    </PageTransition>
  )
}
