import React, { ReactElement} from 'react';
import cx from 'classnames';
import classes from './hero.module.scss';

export type HeroProps = {
    children?: ReactElement,
    className?: string,
    background?: { colour: string },
    title: string,
    text?: "small" | "large"
    style?: React.CSSProperties,
}

const Header = (props: HeroProps) => {  

    const _classes = cx(classes['hero'], {
        [classes[`bg-${props.background?.colour}`]]: props.background?.colour,
        [classes[`text-small`]]: props.text === "small",
    }, props.className);

    return (
        <div className={_classes}>
            <h1>{props.title}</h1>
        </div>
    )
}

export default Header;